<template>
    <div class="site-section">
        <h4 class="mt-4 h2 text-center" style="font-family: 'Comfortaa', cursive !important">{{title}}
        </h4>
        <div class="container text-left pt-44">
            <div class="row" style="font-family: 'Comfortaa', cursive !important">
                <div class="col-md-3 pt-3">
                    <div><span style="color: #bac964;">Author:</span> {{author}}</div>
                    <div><span style="color: #bac964;">Year:</span> {{year}}</div>
                    <div class="small pt-3">Copyright &copy; <br>All Rights reserved</div>
                    <div class="small" style="color: #bac964"><a href="mailto:kolinsky11@yahoo.com">Please contact for full copy</a></div>
                </div>
                <div class="col-md-9 pt-3">
                    <template v-for="data in ddata" :key="data">
                        <img :src="data" class="img-fluid ld" style="width:100% !important" />
                    </template>
                </div>
            </div>
        </div>
    </div>

    <footer class="site-footer" style="background:black">
        <div class="container">

            <div class="row mb-5">
                <div class="col-md-12 text-center">
                    <p>
                        <a href="#" class="social-item"><i class='fab fa-facebook-f' style="padding: 18px;"></i></a>
                        <a href="https://ng.linkedin.com/in/oyadiran-kolawole-b7546965" target="_blank"
                            class="social-item"><i class='fab fa-linkedin' style="padding: 18px;"></i></a>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <p class="col-12 text-center">
                    <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                    Copyright &copy; {{ new Date().getFullYear() }}
                    All rights reserved
                </p>
            </div>

        </div>
    </footer>
</template>

<style>
.ld{
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #333 8%, #abb95e73 18%, #333 33%);
  background-size: 800px 104px;
  height: inherit;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
</style>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import worksData from '@/data'
import router from '@/router'

let title = ref('')
let author = ref('')
let year = ref('')
let ddata = ref([])

const route = useRoute();
const id = route.params.id

let data = worksData.find((d)=>d.id == id)
if (!data){
    router.push({name: 'PageNotFound'})
}else{
    document.title = data.title
    title.value = data.title
    author.value = data.author
    year.value = data.year
    ddata.value = data.data.map((d)=>getImage(d))
}

function getImage(img){
    let image = ''
    try {
    image = require(`../assets/images/${img}`)
    } catch (error) {
       image =  require(`../assets/logo.png`)
    }
    return image
}

</script>