<template>
  <section class="site-hero" style="background-color: black;/**background-image: url(images/image_1.jpg);**/"
    id="section-home" data-stellar-background-ratio="0.5">
    <div class="container">
      <div class="row intro-text align-items-center justify-content-center">
        <div class="col-md-10 text-center pt-5" style="font-family: 'Comfortaa', cursive !important">

          <h1 class="site-heading site-animate">Hello, I'm <strong class="d-block">Kolawole Olawale
              <del style="color: #bac963c9 !important;">Oyadiran</del> (PhD)</strong></h1>
          <strong style="font-family: 'Comfortaa', cursive !important"
            class="d-block text-white text-uppercase letter-spacing"><span
              style="color: #bac963c9">&#9835;</span>Creative & Performative Musiciologist | Music
            Consultant<span style="color: #bac963c9">&#9835;</span></strong>

        </div>
      </div>
    </div>
  </section> <!-- section -->


  <section class="site-section" id="section-portfolio">
    <div class="container">
      <div class="row">
        <div class="section-heading text-center col-md-12">
          <h2 style="font-family: 'Comfortaa', cursive !important"><strong>Musical Works</strong></h2>
        </div>
      </div>
      <div class="filters" style="font-family: 'Poiret One', cursive !important; font-size: large;">
        <ul>
          <li :class="{ active: all }" @click="handleClick('all')" data-filter="*">All</li>
          <li :class="{ active: performances }" @click="handleClick('performances')" data-filter=".performance">
            Performances</li>
          <li :class="{ active: compositions }" @click="handleClick('compositions')" data-filter=".composition">
            Compositions</li>
          <li :class="{ active: recordings }" @click="handleClick('recordings')" data-filter=".recording">Recordings
          </li>
        </ul>
      </div>

      <div class="filters-content">
        <div class="row grid">
          <template v-for="work in works" :key="work.id">
          <div class="col-md-4 col-sm-6 col-lg-3 col-12 all mb-4 "  :class="work.type">
            <div class="single-portfolio sp">
              <div class="relative mb-2">
                <router-link :to="`/${work.type}s/${work.id}`">
                  <div class="thumb ld" style="min-height: 210px;">
                    <img class="image img-fluid" :src="require(`@/assets/images/${work.banner}`)" alt="">
                  </div>
                </router-link>
                <div
                  style="text-transform: uppercase; color: #fff; text-align: center; font-family: 'Comfortaa', cursive !important; font-weight: bold;">
                  <div class="py-1" style="font-size: small;">
                    {{ work.type }}
                  </div>
                </div>
              </div>

              <div class="p-inner pb-1">
                <h6 class="height-limit"
                  style="font-family: 'Poiret One', cursive !important; font-weight: bold; color: #bac964;">
                  {{ work.title }}</h6>
              </div>
            </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
  <!-- .section -->

  <section class="site-section " id="section-resume">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-5">
          <div class="section-heading text-center">
            <h2 style="font-family: 'Comfortaa', cursive !important; font-size: xxx-large;"><strong>My
                Resume</strong></h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h2 class="mb-2" style="font-family: 'Comfortaa', cursive !important;">Education</h2>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                2007</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">PhD in Music</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Obafemi Awolowo University
                Ife</span>
            </div>
          </div>

          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                1998</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Masters in Music
                [Composition]
              </h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Obafemi Awolowo University
                Ife</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                1990</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Bachelors of Music
                [Performance]</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Obafemi Awolowo University
                Ife</span>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-5 mb-2" style="font-family: 'Comfortaa', cursive !important;">Experience</h2>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                2004 - Present</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Lecturer</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Bowen University Iwo</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                ...</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Music Director</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Bowen University</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                2004</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Music Consultant</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Ilesha Anglican Diocese, St.
                Paul
                Anglican Church Ipetu- Ijesa, Osun State.</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                2004</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Music Consultant</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">The Methodist Church Ile-
                Ife</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                2003</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Music Consultant</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">First Baptist Church
                Modakeke,
                Ile- Ife</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                2000</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Music Jurist</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">2nd Akwa Ibom Musical
                Competition
                for Church Choirs2nd Akwa Ibom Musical Competition for Church Choirs</span>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="resume-item" style="height: 100%;">
              <span class="date" style="font-family: 'Comfortaa', cursive !important;"><span
                  class="icon-calendar"></span>
                1992-2000</span>
              <h3 style="font-family: 'Poiret One', cursive !important; font-weight: bold">Coordinator</h3>
              <span class="school" style="font-family: 'Comfortaa', cursive !important;">Deeper Christian Life
                Ministry,
                South-South Zone</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section> <!-- .section -->

  <section class="site-section" id="section-about">
    <div class="container">
      <div class="row mb-5 align-items-center">
        <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
          <img :src="image" alt="Image placeholder" class="img-fluid">
        </div>
        <div class="col-lg-5 pl-lg-5">
          <div class="section-heading">
            <h2 style="font-family: 'Comfortaa', cursive !important"><strong>About Me</strong>
            </h2>
          </div>
          <div style="font-family:'Poiret One', cursive !important;text-align: justify;">
            <p class="lead">Kolawole Emmanuel Olawale (formerly Oyadiran) B.A., M.A., Ph.D.
            </p>
            <p class="mb-5  ">He obtained degrees in music from the Obafemi Awolowo University Ile-Ife, Nigeria,
              specializing in Performance, Composition and African musicology. He has over 30 years’ experience in
              teaching music and music related courses in some Nigerian universities. He is currently a Senior Lecturer
              in the department of Music and Music Director, respectively, at Bowen University Iwo, Nigeria. He has
              contributed immensely in the areas of Applied Music and Composition. Some of his works have been performed
              both within and outside the country. He and some of his works featured in a film titled “Fiwajomi” by
              Mount Zion Drama Ministry (2010); “This is your Life” by The Redeemed Christian National Drama Troupe
              (2012) and Coordinator, Opera Resurgence. His first Musical work, “It was Me” (2011) was premiered at the
              Department of Theatre Arts, Redeemer’s University, RCCG Camp, Mowe, Ogun State
            </p>
          </div>


        </div>
      </div>


    </div>
  </section>

  <section class="site-section" id="section-contact">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-5">
          <div class="section-heading text-center">
            <h2 style="font-family: 'Comfortaa', cursive !important; font-size: xxx-large;"><strong>Get In
                Touch</strong></h2>
          </div>
        </div>

        <div class="col-md-7 mb-5 mb-md-0">
          <form action="" class="site-form">
            <h3 class="mb-5" style="font-family: 'Comfortaa', cursive !important">Get In Touch</h3>
            <div class="form-group">
              <input type="text" class="form-control px-3 py-4" placeholder="Your Name">
            </div>
            <div class="form-group">
              <input type="email" class="form-control px-3 py-4" placeholder="Your Email">
            </div>
            <div class="form-group">
              <input type="email" class="form-control px-3 py-4" placeholder="Your Phone">
            </div>
            <div class="form-group mb-5">
              <textarea class="form-control px-3 py-4" cols="30" rows="10" placeholder="Write a Message"></textarea>
            </div>
            <div class="form-group">
              <button class="btn btn-primary px-4 py-3">Send Message</button>
            </div>
          </form>
        </div>
        <div class="col-md-5 pl-md-5">
          <h3 class="mb-5" style="font-family: 'Comfortaa', cursive !important">My Contact Details</h3>
          <ul class="site-contact-details" style="font-family: 'Poiret One', cursive !important; text-align: justify">
            <li>
              <span class="text-white text-uppercase" style="color: #bac964 !important;">Email</span>
              kolawole.olawale@bowen.edu.ng, <br> kolinsky11@yahoo.com
            </li>
            <li>
              <span class="text-white text-uppercase" style="color: #bac964 !important; ">Phone | Whatsapp</span>
              +234 803 354 4923
            </li>
            <li>
              <span class="text-white text-uppercase" style="color: #bac964 !important;">Address</span>
              Music Department, <br>
              Music Building, <br>
              Bowen University Iwo, Osun State Nigeria.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <footer class="site-footer" style="background:black">
    <div class="container">

      <div class="row mb-5">
        <div class="col-md-12 text-center">
          <p>
            <a href="#" class="social-item"><i class='fab fa-facebook-f' style="padding: 18px;"></i></a>
            <a href="https://ng.linkedin.com/in/oyadiran-kolawole-b7546965" target="_blank" class="social-item"><i
                class='fab fa-linkedin' style="padding: 18px;"></i></a>
          </p>
        </div>
      </div>

      <div class="row mb-3">
        <p class="col-12 text-center">
          <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          Copyright &copy; {{ new Date().getFullYear() }}
          All rights reserved
        </p>
      </div>

    </div>
  </footer>
</template>

<style>
.height-limit {
  height: 1.2em;
  min-height: 2.4em;
}

.sp{
  background-color: #333;
}

.ld{
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #333 8%, #abb95e73 18%, #333 33%);
  background-size: 800px 104px;
  height: inherit;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
</style>

<script setup>
import { ref, onMounted } from 'vue'
import Isotope from 'isotope-layout'
import works from '@/data'

let iso
// let grid = ref(null)
let all = ref(true)
let performances = ref(false)
let compositions = ref(false)
let recordings = ref(false)
let image = require('@/assets/images/pic1.jpg')

document.title = "Kolawole Olawale"

function handleClick(string) {
  all.value = false
  performances.value = false
  compositions.value = false
  recordings.value = false

  switch (string) {
    case 'all':
      all.value = true
      iso.arrange({ filter: '*' });
      break;
    case 'performances':
      performances.value = true
      iso.arrange({ filter: '.performance' });
      break;
    case 'compositions':
      compositions.value = true
      iso.arrange({ filter: '.composition' });
      break;
    case 'recordings':
      recordings.value = true
      iso.arrange({ filter: '.recording' });
      break;
    default:
      break;
  }
}

onMounted(() => {
  iso = new Isotope('.grid', {
    itemSelector: ".all",
    percentPosition: true,
    masonry: {
      columnWidth: ".all"
    }
  })
})

</script>
